import { render, staticRenderFns } from "./storicoRicercaCondidati.vue?vue&type=template&id=da9a0ad2&scoped=true&"
import script from "./storicoRicercaCondidati.vue?vue&type=script&lang=js&"
export * from "./storicoRicercaCondidati.vue?vue&type=script&lang=js&"
import style0 from "./storicoRicercaCondidati.vue?vue&type=style&index=0&id=da9a0ad2&scoped=true&lang=css&"
import style1 from "./storicoRicercaCondidati.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da9a0ad2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBottomSheet,VBtn,VCol,VDatePicker,VImg,VListItem,VListItemTitle,VMenu,VRow,VSheet,VTextField,VTextarea})
