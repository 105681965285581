import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIStatiIter {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }



    async insertStatiIter(statiiter, user) {

        let formData = new FormData();

        formData.append("username", user);
   
        for (var key in statiiter) {
            formData.append(key, statiiter[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitIterCollInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goInsertStatiIter(statiiter, user) {

        let formData = new FormData();

        formData.append("username", user);
   
        for (var key in statiiter) {
            formData.append(key, statiiter[key]);
        }

        return axios.post(this.getUrlDomain("goInsertStatoIter2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEditStato(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   
     
        return axios.post(this.getUrlDomain("TwoRecruitIterCollInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goInitEditStato(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   
     
        return axios.post(this.getUrlDomain("goEditIterStatoColloquio2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateStatiIter(statiiter, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   
        for (var key in statiiter) {
            formData.append(key, statiiter[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitIterCollUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async goUpdateStatiIter(statiiter, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   
        for (var key in statiiter) {
            formData.append(key, statiiter[key]);
        }

        return axios.post(this.getUrlDomain("goUpdateIterStatoColloquio2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async deleteStati(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   

        return axios.post(this.getUrlDomain("TwoRecruitIterCollDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goDeleteStati(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   

        return axios.post(this.getUrlDomain("goDeleteStatoIterColloquio2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APIStatiIter ({
    url: "https://services.ebadge.it/public/api/"
})
