import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIRegioni {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    
    async initInsert(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goInitInsert(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);

        return axios.post(this.getUrlDomain("goInitInsertRegione2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertRegione(regione, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in regione) {
            formData.append(key, regione[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRegioniInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async goInsertRegione(regione, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in regione) {
            formData.append(key, regione[key]);
        }

        return axios.post(this.getUrlDomain("goInsertRegione2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async initEdit(id, user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goInitEdit(id, user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("goEditRegione2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async updateRegione(regione, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in regione) {
            formData.append(key, regione[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitRegioniUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goUpdateRegione(regione, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in regione) {
            formData.append(key, regione[key]);
        }

        return axios.post(this.getUrlDomain("goUpdateRegione2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteRegione(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goDeleteRegione(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("goDeleteRegione2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goInitFilter(user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("goGetCompanyFilter2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    

}

export default new APIRegioni({
    url: "https://services.ebadge.it/public/api/"
})
